<script setup>
  import PickProduct from '@/views/index/PickProduct'
  import { getCurrentInstance, onMounted, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const { proxy } = getCurrentInstance()

  //去商品列表页
  const gotoProList = () => {
    proxy.$router.replace({
      path: '/'
    })
  }
</script>

<template>
  <div class="content">
    <div class="cart_header">
      {{ $t('Shopping Cart') }}
    </div>
    <div class="pro_list_none mt0 rowCC">
      <img src="~img/pro_list_none.svg" />
      <div class="txt">
        <p>{{ $t('Cart is empty') }}</p>
        <div class="btn">
          <a @click="gotoProList()" style="margin-left: 0px">{{ $t('Continue Shopping') }}</a>
        </div>
      </div>
    </div>
    <PickProduct />
  </div>
</template>

<style lang="less" scoped>
  .content {
    position: reactive;
    margin: 0 auto;
    width: var(--minWidth);
    margin-bottom: 20px;
  }

  .cart_header {
    float: left;
    width: 100%;
    font-size: 18px;
    margin: 20px 0;
  }

  .pro_list_none {
    width: 100%;
    background: #fff;
    margin-top: 20px;
    padding: 20px;
    img {
      width: 100px;
      height: 100px;
    }
    .txt {
      width: fit-content;
      padding-left: 15px;
      font-size: 16px;
      .btn {
        width: 100%;
        margin-top: 15px;
        font-size: 14px;
        a {
          margin-left: 15px;
          color: var(--btnBgColor);
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
</style>
